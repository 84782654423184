import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Video from '../components/videos/video'

const IndexPage = () => (
  <Layout>
    <SEO title='OR-PRINTER::DEMOS' />
    <Header siteTitle='DEMOS' />

    <Container>
      <div className='container'>
        <div className='row justify-content-md-center'>
          <div className='col-12 col-md-6'>
            <Video
              videoSrcURL='https://www.youtube.com/embed/kDwJr4pbryQ'
              videoTitle='OR-PRINTER 5000E'
            />
          </div>
          <div className='col-12 col-md-6 '>
            <Video
              videoSrcURL='https://www.youtube.com/embed/O1051NzhUCA'
              videoTitle='MAQUINA TERMOIMPRESION MANUAL'
            />
          </div>
        </div>
        <div className='row justify-content-md-center'>
          <div className='col-12 col-md-6'>
            <Video
              videoSrcURL='https://www.youtube.com/embed/yQh0ooP_gE0'
              videoTitle='Termoimpresión de Cintas'
            />
          </div>
          <div className='col-12 col-md-6 '>
            <Video
              videoSrcURL='https://www.youtube.com/embed/zoQYhYGaaC0'
              videoTitle='Termoimpresión de Etiquetas'
            />
          </div>
        </div>
      </div>
    </Container>
  </Layout>
)

export default IndexPage
